body {
  margin: 0;
  padding: 0;
  font-family: "1942";
  background-color: #5e2ca5;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "1942";
  src: local("1942"), url(./1942.ttf);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

noselect {
  user-select: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

input {
  border-width: 0;
  border-bottom-width: 5px;
  border-bottom: solid white;
  background-color: transparent;
  color: white;
  font-size: 1em;
}

button {
  border-width: 0;
  background-color: transparent;
  color: white;
}
